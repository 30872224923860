import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { logout } from '../utils/auth';
import Loadable from '../components/data/loadable';
import { useNotifications } from '../contexts/notifications-provider';
import SEO from '../components/layout/seo';

const SignOut = () => {
  const { addNotification } = useNotifications();

  useEffect(() => {
    logout(() => {
      addNotification({
        type: 'info',
        body: "You have been signed out.",
        timeout: 3000,
      });
      const parsed = window.location.search ? queryString.parse(window.location.search) : {};
      const redir = parsed && parsed.redir ? parsed.redir : '/sign-in';
      navigate(redir);
    });
  }, []);


  return (
    <Loadable loading={true}>
      <SEO title="Sign out" />
      You are being signed out...
    </Loadable>
  );
 
};

export default SignOut;
